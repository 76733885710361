import React from "react";

interface Props {
	children: React.ReactNode;
	display?: boolean;
	as?: string;
	className?: string;
}

const H3: React.FC<Props> = ({ children, display = false, as = "h3", className }) => {
	const classes = `font-display ${display ? "text-xl md:text-2xl" : "text-lg md:text-xl"} ${
		className ? className : ""
	}`;
	return React.createElement(as, { className: classes }, children);
};

export default H3;
