import { Link } from "gatsby";
import React from "react";

const Datenschutz = () => {
	return (
		<div className="text-xs text-center">
			<p>
				Mit dem Absenden bestätigen Sie, dass Sie unsere{" "}
				<Link to="/datenschutz/" className="underline">
					Datenschutzerklärung
				</Link>{" "}
				akzeptieren.
			</p>
		</div>
	);
};

export default Datenschutz;
