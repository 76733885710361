import React from "react";
import { CheckIcon } from "@heroicons/react/outline";
import H3 from "~components/typography/H3";
import P from "~components/typography/P";

interface Props {
	name: string;
	description?: string;
}

const FeatureCheckicon: React.FC<Props> = ({ name, description }) => {
	return (
		<div className="relative">
			<dt>
				<CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
				<H3 as="p" className="ml-9">
					{name}
				</H3>
			</dt>
			{description && (
				<P as="dd" className="mt-2 ml-9">
					{description}
				</P>
			)}
		</div>
	);
};

export default FeatureCheckicon;
