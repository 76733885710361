import React from "react";

interface Props {
	cols: number;
	children: React.ReactNode;
	itemsCenter?: boolean;
	itemsStart?: boolean;
	gap?: string;
	className?: string;
	id?: string;
}

const Grid: React.FC<Props> = ({ cols, children, itemsCenter, itemsStart, gap, className, id }) => {
	const classes = `grid grid-cols-1 ${gap ? gap : "gap-16"} ${itemsCenter ? "items-center" : ""} ${
		itemsStart ? "items-start" : ""
	}  ${className ? `${className}` : ""}`;

	switch (cols) {
		case 2:
			return (
				<div id={id ? id : ""} className={`${classes} md:grid-cols-2`}>
					{children}
				</div>
			);
		case 3:
			return (
				<div id={id ? id : ""} className={`${classes} md:grid-cols-3`}>
					{children}
				</div>
			);
		case 4:
			return (
				<div id={id ? id : ""} className={`${classes} md:grid-cols-4`}>
					{children}
				</div>
			);
		case 5:
			return (
				<div id={id ? id : ""} className={`${classes} md:grid-cols-5`}>
					{children}
				</div>
			);
		case 6:
			return (
				<div id={id ? id : ""} className={`${classes} md:grid-cols-6`}>
					{children}
				</div>
			);
		default:
			return (
				<div id={id ? id : ""} className={`${classes} md:grid-cols-2`}>
					{children}
				</div>
			);
	}
};

export default Grid;
