import React from "react";

interface Props {
	// Reqiuerd
	type: string;
	id: string;
	name: string;
	placeholder: string;
	// Optionals
	label?: string;
	required?: boolean;
	autoComplete?: string;
	inputMode?: "text" | "search" | "email" | "tel" | "url" | "none" | "numeric" | "decimal" | undefined;
	min?: string;
	max?: string;
	pattern?: string;
	value?: string;
	asFragment?: boolean;
	inlineLabel?: string;
	className?: string;
	classNameContainer?: string;
	// Functions
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: () => (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input: React.FC<Props> = (props, ref) => {
	const {
		type,
		id,
		name,
		placeholder,
		label,
		required = false,
		autoComplete,
		inputMode = "text",
		min,
		max,
		pattern,
		value,
		asFragment = false,
		inlineLabel,
		className,
		classNameContainer,
		onChange,
		onKeyDown,
	} = props;

	return (
		<InputContainer asFragment={asFragment} className={classNameContainer}>
			<>
				{label && (
					<label className="block mb-1 text-xs text-left" htmlFor={id}>
						{label}
						{required && "*"}
					</label>
				)}
				<div className="relative">
					<input
						className={`w-full appearance-none rounded-xl border-0 bg-stone-50 p-4 text-lg leading-tight focus:border-transparent focus:outline-none focus:ring-1 focus:ring-inset focus:ring-black ${
							className ? className : ""
						}`}
						id={id}
						name={name}
						placeholder={placeholder}
						type={type}
						required={required}
						min={min}
						max={max}
						pattern={pattern}
						autoComplete={autoComplete}
						inputMode={inputMode}
						onChange={onChange}
						onKeyDown={onKeyDown}
						value={value}
						ref={ref}
					/>
					{inlineLabel && (
						<div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
							<span className="text-gray-500 sm:text-sm">{inlineLabel}</span>
						</div>
					)}
				</div>
			</>
		</InputContainer>
	);
};

interface PropsInputContainer {
	asFragment?: boolean;
	children: React.ReactNode;
	className?: string;
}

const InputContainer: React.FC<PropsInputContainer> = ({ asFragment, className, children }) => {
	if (asFragment) return <>{children}</>;
	return <div className={className ? className : ""}>{children}</div>;
};

// @ts-ignore: Unreachable code error
export default React.forwardRef(Input);
