import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import H1 from "~components/typography/H1";
import P from "~components/typography/P";
import Button from "~components/common/Button";

export const Hero = () => {
	const data = useStaticQuery(graphql`
		{
			header: file(relativePath: { eq: "header_image.jpeg" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`);

	const image = getImage(data.header);

	return (
		<>
			<Container spacing="disabled" className="py-8">
				<div className="relative overflow-hidden shadow-xl animate-slide-in-up rounded-xl">
					<div className="absolute inset-0">
						{image && (
							<GatsbyImage
								image={image}
								alt="Asbestsanierung, Abbruch, Entsorgung und Umweltlasten"
								className="object-cover w-full h-full rounded-xl opacity-30"
							/>
						)}

						<div className="absolute inset-0 bg-black bg-opacity-10" />
						<div className="absolute inset-0 bg-gradient-to-r from-brand-green to-green-800 mix-blend-multiply" />
					</div>
					<div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
						<div className="max-w-2xl mx-auto text-center">
							<H1 className="mb-5 font-bold text-white" display>
								Asbestsanierung, Abbruch, Entsorgung und Umweltlasten
							</H1>
							<P className="mb-8" textColor="text-gray-100">
								Kotur Entsorgungstechnik GmbH ist Ihr professioneller Partner, wenn es um Asbestsanierung, Abbruch,
								Entsorgung und Umweltlasten geht. Der Einsatz modernster Technik und die hohe Qualifikation unserer
								erfahrenen Mitarbeiter, ist Garantie für termingerechte und wirtschaftliche Realisierung unserer
								Leistungen. Die stätige Kommunikation zwischen allen Beteiligten und Lösung von komplexen
								Herausforderungen ist unser Steckenpferd.
							</P>
						</div>
						<div className="max-w-sm mx-auto mt-10 sm:flex sm:max-w-none sm:justify-center">
							<div className="space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 sm:space-y-0">
								<Button text="Kontaktdaten" anchor="/#kontakt" className="w-full" />
								<Button text="Infos" anchor="/#leistungen" className="w-full" white />
							</div>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
};

export default Hero;
