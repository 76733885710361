import React from "react";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import H3 from "~components/typography/H3";
import P from "~components/typography/P";
import { PhoneIcon, DocumentIcon, MailIcon } from "@heroicons/react/outline";

interface Props {
	name: string;
	role: string;
	imageData?: IGatsbyImageData;
	phone?: string;
	fax?: string;
	mail?: string;
}

const People: React.FC<Props> = ({ imageData, name, role, phone, fax, mail }) => {
	const image = imageData ? getImage(imageData) : null;

	return (
		<li>
			<div className="flex items-center space-x-4 lg:space-x-6">
				{image && (
					<GatsbyImage image={image} alt={`Profil: ${name}`} className="w-16 h-16 rounded-full lg:h-20 lg:w-20" />
				)}
				<div className="space-y-1 text-lg font-medium leading-6">
					<H3 as="p">{name}</H3>
					<P textColor="text-gray-700 text-xs">{role}</P>
					{(phone || fax || mail) && (
						<div className="pt-3 space-y-1">
							{phone && (
								<P className="flex items-center text-sm">
									<PhoneIcon className="w-4 h-4 mr-3">
										<title>Telefonnummer</title>
									</PhoneIcon>
									{phone}
								</P>
							)}
							{fax && (
								<P className="flex items-center text-sm">
									<DocumentIcon className="w-4 h-4 mr-3">
										<title>Fax</title>
									</DocumentIcon>
									{fax}
								</P>
							)}
							{mail && (
								<P className="flex items-center text-sm">
									<MailIcon className="w-4 h-4 mr-3">
										<title>E-Mail</title>
									</MailIcon>
									{mail}
								</P>
							)}
						</div>
					)}
				</div>
			</div>
		</li>
	);
};

export default People;
