// Packages
import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Types
import type { SiteMetadata, GatsbyImagePropsMeta, Location } from "~types/Gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";

// Components
import Layout from "~components/layouts/Layout";
import Hero from "~components/sections/Hero";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";
import { DocumentIcon, MailIcon, PhoneIcon } from "@heroicons/react/outline";
import Blockquote from "~components/common/Blockquote";
import H3 from "~components/typography/H3";
import FeatureCheckicon from "~components/feauture/FeatureCheckicon";
import People from "~components/common/People";
import FormGeneral from "~components/forms/FormGeneral";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata,
	};
	seoImage: {
		facebook: GatsbyImagePropsMeta,
		twitter: GatsbyImagePropsMeta,
	};
	profileDummy: IGatsbyImageData;
	placeholder: IGatsbyImageData;
	imageSection: IGatsbyImageData;
	imageCite: IGatsbyImageData;
}

const features = [
	{
		name: "Asbestsanierung",
	},
	{
		name: "Abbruch",
	},
	{
		name: "Entsorgung",
	},
	{
		name: "Umweltlasten",
	},
];

const team = [
	{
		name: "Michael Kotur",
		role: "Geschäftsfüher",
		phone: "+49-(0)281-94229-700",
		fax: "+49-(0)281-94229-701",
		mail: "m.kotur@kotur-gruppe.de",
	},

	{
		name: "Michaela Kotur",
		role: "Sekretariat",
		phone: "+49-(0)281-94229-700",
		fax: "+49-(0)281-94229-701",
		mail: "info@kotur-gruppe.de",
	},

	{
		name: "Stefan Ohn",
		role: "Assistent der Geschäftsleitung / Buchhaltung",
		phone: "+49-(0)281-94229-700",
		fax: "+49-(0)281-94229-701",
		mail: "s.ohn@kotur-gruppe.de",
	},

	{
		name: "Dennis Wiesner",
		role: "Backoffice",
		phone: "+49-(0)281-94229-700",
		fax: "+49-(0)281-94229-701",
		mail: "d.wiesner@kotur-gruppe.de",
	},

	{ name: "Mitar Kotur", role: "Polier" },

	{ name: "Gheorghe Florin Popescu", role: "Vorarbeiter" },
];

export default function Page({ data, location }: { data: DataProps, location: Location }) {
	const imagePlaceholder = getImage(data.placeholder);
	const imageSection = getImage(data.imageSection);
	const imageCite = getImage(data.imageCite);

	return (
		<Layout
			location={location}
			title="Startseite"
			desc=""
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<Hero />

			{/* About Text Section */}
			<Container id="ueber-uns">
				<Grid cols={2}>
					<div className="mb-2">
						<H2>Asbestsanierung mit Kotur</H2>
						<H3 as="p" className="mb-4">
							Über uns
						</H3>
					</div>
					<div>
						<P className="mb-4">
							Die Firma Kotur Entsorgungstechnik GmbH ist ein mittelständiges niederrheinisches Familienunternehmen und
							versteht sich als modernes Entsorgungsunternehmen, das auf die zukünftigen Recyclingsysteme ( siehe Rubrik
							Recycling) der Wirtschaftswelt kundenorientiert und individuell eingeht. Wir zeichnen uns durch
							Kundenfreundlichkeit, Innovationsorientierung, sowie dem Streben nach nachhaltigem Handeln aus.{" "}
						</P>
						<P className="mb-4">
							Die Tätigkeitsfelder unserer Firma umfassen die Erstellung, Planung und Ausführung von Leistungen in den
							Bereichen Abbruch, Entsorgung und Schadstoffsanierung. Unser Kerngeschäft ist nach wie vor, das Entsorgen
							von Umweltlasten und das Sanieren von Gebäuden nach den gesetzlichen Richtlinien. Eine immer größere
							Bedeutung erfährt auch die Einhaltung der Auflagen und gesetzlichen Vorschriften. Unser Servicebereich im
							Entsorgungstechnik -Management wird kontinuierlich ausgebaut und optimiert.{" "}
						</P>
						<P>
							Partnerschaftliche Zusammenarbeit bedeutet in unserem Unternehmen faires Handeln gegenüber Kunden,
							Kooperationspartnern und Lieferanten. Die Loyalität und das weitreichende Know-how unserer Mitarbeiter
							bilden dabei das Fundament für den Erfolg unseres Unternehmens. Durch stetige Weiterbildung unserer
							Fachkräfte gehen wir auf die sich stets verändernden Bedürfnisse des Marktes ein.{" "}
						</P>
					</div>
				</Grid>
			</Container>

			{/* Image */}
			<Container>
				{imageSection && <GatsbyImage image={imageSection} alt="" className="rounded-xl shadow-xl" />}
			</Container>

			{/* Features Section */}
			<Container id="leistungen">
				<Grid cols={2}>
					<div>
						<H2 className="mb-2">Leistungen</H2>
						<H3 as="p" className="mb-4">
							Vom Profi
						</H3>
						{/* <P>
							Esse deserunt irure eiusmod magna labore irure. Non consequat et non magna proident consectetur officia do
							nisi ullamco. Laborum qui id mollit sit ullamco minim veniam commodo irure anim exercitation commodo non
							tempor.
						</P>
						<div className="pt-6 mt-8 border-t border-gray-200">
							<Blockquote
								quote="Cras velit quis eros eget rhoncus lacus ultrices sed diam. Sit orci risus aenean curabitur
											donec aliquet. Mi venenatis in euismod ut."
								title="Max Musterfrau, Muster GmbH"
								imageData={data.profileDummy}
							/>
						</div> */}
					</div>
					<div>
						<dl className="space-y-10 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:gap-x-6 sm:gap-y-10 sm:space-y-0 lg:gap-x-8">
							{features.map((feature) => (
								<FeatureCheckicon key={feature.name} name={feature.name} />
							))}
						</dl>
					</div>
				</Grid>
			</Container>

			{/* Team Section */}

			<Container id="team">
				<Grid cols={3}>
					<div>
						<H2 className="mb-4">Unser Team</H2>
						{/* <P>Reprehenderit ullamco veniam ea laborum deserunt id.</P> */}
					</div>
					<div className="md:col-span-2">
						<ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
							{team.map((member) => (
								<People
									name={member.name}
									role={member.role}
									phone={member.phone}
									fax={member.fax}
									mail={member.mail}
									key={member.name}
								/>
							))}
						</ul>
					</div>
				</Grid>
			</Container>

			{/* Testmonial */}

			{/* <Container>
				<div className="relative px-8 py-24 overflow-hidden shadow-xl rounded-xl bg-brand-green lg:grid lg:grid-cols-3 lg:gap-x-8 lg:px-16">
					<div className="absolute inset-0 opacity-50 mix-blend-multiply saturate-0 filter">
						{imageCite && (
							<GatsbyImage
								image={imageCite}
								alt="Asbestsanierung, Abbruch, Entsorgung und Umweltlasten"
								className="object-cover w-full h-full"
							/>
						)}
					</div>
					<div className="relative lg:col-span-2">
						<blockquote className="mt-6 text-white">
							<P textColor="text-white" className="text-lg italic">
								&ldquo;Officia enim laborum reprehenderit excepteur. Ullamco nulla qui excepteur fugiat. Lorem irure
								incididunt ad nulla magna esse aliquip ut aliquip. Tempor mollit nulla proident proident velit nisi est
								in dolor cupidatat. Anim minim ea commodo labore cupidatat labore non voluptate esse. Reprehenderit aute
								ullamco occaecat enim consequat et laborum ut elit. Aliqua voluptate eiusmod pariatur ea cupidatat nulla
								tempor labore in.&rdquo;
							</P>
							<footer className="mt-6">
								<p className="flex flex-col font-medium">
									<span>Michael Kotur</span>
									<span>Geschäftsfüher, Kotur Entsorgungstechnik GmbH</span>
								</p>
							</footer>
						</blockquote>
					</div>
				</div>
			</Container> */}

			{/* Contact Section */}

			<div className="relative bg-white py-8" id="kontakt">
				<div className="absolute inset-0 hidden md:block">
					<div className="absolute inset-y-0 left-0 w-1/2 bg-stone-50" />
				</div>
				<Container className="relative">
					<Grid cols={2}>
						<div>
							<H2 className="mb-4">Kontaktdaten</H2>
							{/* <P>
								Irure cupidatat nostrud anim sunt adipisicing consectetur nisi pariatur qui exercitation pariatur in
								eiusmod quis.
							</P> */}
							<dl className="mt-8 text-base text-gray-500">
								<div>
									<dt className="sr-only">Adresse</dt>
									<dd>
										<p>{data.site.siteMetadata.contact.street}</p>
										<p>
											{data.site.siteMetadata.contact.plz} {data.site.siteMetadata.contact.city}
										</p>
									</dd>
								</div>
								<div className="mt-6">
									<dt className="sr-only">Telefon</dt>
									<dd className="flex">
										<PhoneIcon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
										<span className="ml-3">{data.site.siteMetadata.contact.phone}</span>
									</dd>
								</div>
								<div className="mt-3">
									<dt className="sr-only">Fax</dt>
									<dd className="flex">
										<DocumentIcon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
										<span className="ml-3">{data.site.siteMetadata.contact.fax}</span>
									</dd>
								</div>
								<div className="mt-3">
									<dt className="sr-only">E-Mail</dt>
									<dd className="flex">
										<MailIcon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
										<span className="ml-3">{data.site.siteMetadata.contact.mail}</span>
									</dd>
								</div>
							</dl>
						</div>
						<div>
							<FormGeneral formName="Kontaktformular" />
						</div>
					</Grid>
				</Container>
			</div>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		seoImage: file(relativePath: { eq: "placeholder-meta.png" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}
		imageSection: file(relativePath: { eq: "section_image.jpeg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		imageCite: file(relativePath: { eq: "cite_image.jpeg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		profileDummy: file(relativePath: { eq: "profile.jpeg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		placeholder: file(relativePath: { eq: "placeholder-meta.png" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
		site {
			siteMetadata {
				contact {
					city
					mail
					phone
					fax
					plz
					street
				}
			}
		}
	}
`;
